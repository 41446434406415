<template>
  <div class="page-wrapper">
    <ChangePassword />
  </div>
</template>

<script>

import ChangePassword from './components/ChangePassword'

export default {
  name: 'UserProfile',
  components: {
    ChangePassword
  },
}
</script>

<style scoped>

</style>